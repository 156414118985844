.vis .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .vis-active {
    box-shadow: 0 0 10px #86d5f8;
  }
  .vis [class*="span"] {
    min-height: 0;
    width: auto;
  }
  div.vis-configuration {
    position: relative;
    display: block;
    float: left;
    font-size: 12px;
  }
  div.vis-configuration-wrapper {
    display: block;
    width: 700px;
  }
  div.vis-configuration-wrapper::after {
    clear: both;
    content: "";
    display: block;
  }
  div.vis-configuration.vis-config-option-container {
    display: block;
    width: 495px;
    background-color: #fff;
    border: 2px solid #f7f8fa;
    border-radius: 4px;
    margin-top: 20px;
    left: 10px;
    padding-left: 5px;
  }
  div.vis-configuration.vis-config-button {
    display: block;
    width: 495px;
    height: 25px;
    vertical-align: middle;
    line-height: 25px;
    background-color: #f7f8fa;
    border: 2px solid #ceced0;
    border-radius: 4px;
    margin-top: 20px;
    left: 10px;
    padding-left: 5px;
    cursor: pointer;
    margin-bottom: 30px;
  }
  div.vis-configuration.vis-config-button.hover {
    background-color: #4588e6;
    border: 2px solid #214373;
    color: #fff;
  }
  div.vis-configuration.vis-config-item {
    display: block;
    float: left;
    width: 495px;
    height: 25px;
    vertical-align: middle;
    line-height: 25px;
  }
  div.vis-configuration.vis-config-item.vis-config-s2 {
    left: 10px;
    background-color: #f7f8fa;
    padding-left: 5px;
    border-radius: 3px;
  }
  div.vis-configuration.vis-config-item.vis-config-s3 {
    left: 20px;
    background-color: #e4e9f0;
    padding-left: 5px;
    border-radius: 3px;
  }
  div.vis-configuration.vis-config-item.vis-config-s4 {
    left: 30px;
    background-color: #cfd8e6;
    padding-left: 5px;
    border-radius: 3px;
  }
  div.vis-configuration.vis-config-header {
    font-size: 18px;
    font-weight: 700;
  }
  div.vis-configuration.vis-config-label {
    width: 120px;
    height: 25px;
    line-height: 25px;
  }
  div.vis-configuration.vis-config-label.vis-config-s3 {
    width: 110px;
  }
  div.vis-configuration.vis-config-label.vis-config-s4 {
    width: 100px;
  }
  div.vis-configuration.vis-config-colorBlock {
    top: 1px;
    width: 30px;
    height: 19px;
    border: 1px solid #444;
    border-radius: 2px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  input.vis-configuration.vis-config-checkbox {
    left: -5px;
  }
  input.vis-configuration.vis-config-rangeinput {
    position: relative;
    top: -5px;
    width: 60px;
    padding: 1px;
    margin: 0;
    pointer-events: none;
  }
  input.vis-configuration.vis-config-range {
    -webkit-appearance: none;
    border: 0 solid #fff;
    background-color: rgba(0, 0, 0, 0);
    width: 300px;
    height: 20px;
  }
  input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #dedede;
    background: -moz-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #dedede),
      color-stop(99%, #c8c8c8)
    );
    background: -webkit-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -ms-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: linear-gradient(to bottom, #dedede 0, #c8c8c8 99%);
    border: 1px solid #999;
    box-shadow: #aaa 0 0 3px 0;
    border-radius: 3px;
  }
  input.vis-configuration.vis-config-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #14334b;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #3876c2;
    background: -moz-linear-gradient(top, #3876c2 0, #385380 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #3876c2),
      color-stop(100%, #385380)
    );
    background: -webkit-linear-gradient(top, #3876c2 0, #385380 100%);
    background: -o-linear-gradient(top, #3876c2 0, #385380 100%);
    background: -ms-linear-gradient(top, #3876c2 0, #385380 100%);
    background: linear-gradient(to bottom, #3876c2 0, #385380 100%);
    box-shadow: #111927 0 0 1px 0;
    margin-top: -7px;
  }
  input.vis-configuration.vis-config-range:focus {
    outline: 0;
  }
  input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
    background: #9d9d9d;
    background: -moz-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #9d9d9d),
      color-stop(99%, #c8c8c8)
    );
    background: -webkit-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
    background: -o-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
    background: -ms-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
    background: linear-gradient(to bottom, #9d9d9d 0, #c8c8c8 99%);
  }
  input.vis-configuration.vis-config-range::-moz-range-track {
    width: 300px;
    height: 10px;
    background: #dedede;
    background: -moz-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #dedede),
      color-stop(99%, #c8c8c8)
    );
    background: -webkit-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: -ms-linear-gradient(top, #dedede 0, #c8c8c8 99%);
    background: linear-gradient(to bottom, #dedede 0, #c8c8c8 99%);
    border: 1px solid #999;
    box-shadow: #aaa 0 0 3px 0;
    border-radius: 3px;
  }
  input.vis-configuration.vis-config-range::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #385380;
  }
  input.vis-configuration.vis-config-range:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px;
  }
  input.vis-configuration.vis-config-range::-ms-track {
    width: 300px;
    height: 5px;
    background: 0 0;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }
  input.vis-configuration.vis-config-range::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }
  input.vis-configuration.vis-config-range::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  input.vis-configuration.vis-config-range::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #385380;
  }
  input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
    background: #888;
  }
  input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
    background: #ccc;
  }
  .vis-configuration-popup {
    position: absolute;
    background: rgba(57, 76, 89, 0.85);
    border: 2px solid #f2faff;
    line-height: 30px;
    height: 30px;
    width: 150px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }
  .vis-configuration-popup:after,
  .vis-configuration-popup:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .vis-configuration-popup:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(57, 76, 89, 0.85);
    border-width: 8px;
    margin-top: -8px;
  }
  .vis-configuration-popup:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #f2faff;
    border-width: 12px;
    margin-top: -12px;
  }
  div.vis-tooltip {
    position: absolute;
    visibility: hidden;
    padding: 5px;
    white-space: normal;
    font-family: verdana;
    font-size: 14px;
    color: #000;
    background-color: #f5f4ed;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #808074;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    z-index: 5;
  }
  div.vis-color-picker {
    position: absolute;
    top: 0;
    left: 30px;
    margin-top: -140px;
    margin-left: 30px;
    width: 310px;
    height: 444px;
    z-index: 1;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px 0;
  }
  div.vis-color-picker div.vis-arrow {
    position: absolute;
    top: 147px;
    left: 5px;
  }
  div.vis-color-picker div.vis-arrow::after,
  div.vis-color-picker div.vis-arrow::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  div.vis-color-picker div.vis-arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 30px;
    margin-top: -30px;
  }
  div.vis-color-picker div.vis-color {
    position: absolute;
    width: 289px;
    height: 289px;
    cursor: pointer;
  }
  div.vis-color-picker div.vis-brightness {
    position: absolute;
    top: 313px;
  }
  div.vis-color-picker div.vis-opacity {
    position: absolute;
    top: 350px;
  }
  div.vis-color-picker div.vis-selector {
    position: absolute;
    top: 137px;
    left: 137px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 1px solid #fff;
    background: #4c4c4c;
    background: -moz-linear-gradient(
      top,
      #4c4c4c 0,
      #595959 12%,
      #666 25%,
      #474747 39%,
      #2c2c2c 50%,
      #000 51%,
      #111 60%,
      #2b2b2b 76%,
      #1c1c1c 91%,
      #131313 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #4c4c4c),
      color-stop(12%, #595959),
      color-stop(25%, #666),
      color-stop(39%, #474747),
      color-stop(50%, #2c2c2c),
      color-stop(51%, #000),
      color-stop(60%, #111),
      color-stop(76%, #2b2b2b),
      color-stop(91%, #1c1c1c),
      color-stop(100%, #131313)
    );
    background: -webkit-linear-gradient(
      top,
      #4c4c4c 0,
      #595959 12%,
      #666 25%,
      #474747 39%,
      #2c2c2c 50%,
      #000 51%,
      #111 60%,
      #2b2b2b 76%,
      #1c1c1c 91%,
      #131313 100%
    );
    background: -o-linear-gradient(
      top,
      #4c4c4c 0,
      #595959 12%,
      #666 25%,
      #474747 39%,
      #2c2c2c 50%,
      #000 51%,
      #111 60%,
      #2b2b2b 76%,
      #1c1c1c 91%,
      #131313 100%
    );
    background: -ms-linear-gradient(
      top,
      #4c4c4c 0,
      #595959 12%,
      #666 25%,
      #474747 39%,
      #2c2c2c 50%,
      #000 51%,
      #111 60%,
      #2b2b2b 76%,
      #1c1c1c 91%,
      #131313 100%
    );
    background: linear-gradient(
      to bottom,
      #4c4c4c 0,
      #595959 12%,
      #666 25%,
      #474747 39%,
      #2c2c2c 50%,
      #000 51%,
      #111 60%,
      #2b2b2b 76%,
      #1c1c1c 91%,
      #131313 100%
    );
  }
  div.vis-color-picker div.vis-new-color {
    position: absolute;
    width: 140px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    top: 380px;
    left: 159px;
    text-align: right;
    padding-right: 2px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: middle;
    line-height: 20px;
  }
  div.vis-color-picker div.vis-initial-color {
    position: absolute;
    width: 140px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    top: 380px;
    left: 10px;
    text-align: left;
    padding-left: 2px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: middle;
    line-height: 20px;
  }
  div.vis-color-picker div.vis-label {
    position: absolute;
    width: 300px;
    left: 10px;
  }
  div.vis-color-picker div.vis-label.vis-brightness {
    top: 300px;
  }
  div.vis-color-picker div.vis-label.vis-opacity {
    top: 338px;
  }
  div.vis-color-picker div.vis-button {
    position: absolute;
    width: 68px;
    height: 25px;
    border-radius: 10px;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    top: 410px;
    border: 2px solid #d9d9d9;
    background-color: #f7f7f7;
    cursor: pointer;
  }
  div.vis-color-picker div.vis-button.vis-cancel {
    left: 5px;
  }
  div.vis-color-picker div.vis-button.vis-load {
    left: 82px;
  }
  div.vis-color-picker div.vis-button.vis-apply {
    left: 159px;
  }
  div.vis-color-picker div.vis-button.vis-save {
    left: 236px;
  }
  div.vis-color-picker input.vis-range {
    width: 290px;
    height: 20px;
  }
  div.vis-network div.vis-manipulation {
    box-sizing: content-box;
    border-width: 0;
    border-bottom: 1px;
    border-style: solid;
    border-color: #d6d9d8;
    background: #fff;
    background: -moz-linear-gradient(
      top,
      #fff 0,
      #fcfcfc 48%,
      #fafafa 50%,
      #fcfcfc 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #fff),
      color-stop(48%, #fcfcfc),
      color-stop(50%, #fafafa),
      color-stop(100%, #fcfcfc)
    );
    background: -webkit-linear-gradient(
      top,
      #fff 0,
      #fcfcfc 48%,
      #fafafa 50%,
      #fcfcfc 100%
    );
    background: -o-linear-gradient(
      top,
      #fff 0,
      #fcfcfc 48%,
      #fafafa 50%,
      #fcfcfc 100%
    );
    background: -ms-linear-gradient(
      top,
      #fff 0,
      #fcfcfc 48%,
      #fafafa 50%,
      #fcfcfc 100%
    );
    background: linear-gradient(
      to bottom,
      #fff 0,
      #fcfcfc 48%,
      #fafafa 50%,
      #fcfcfc 100%
    );
    padding-top: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 28px;
  }
  div.vis-network div.vis-edit-mode {
    position: absolute;
    left: 0;
    top: 5px;
    height: 30px;
  }
  div.vis-network div.vis-close:hover {
    opacity: 0.6;
  }
  div.vis-network div.vis-edit-mode div.vis-button,
  div.vis-network div.vis-manipulation div.vis-button {
    float: left;
    font-family: verdana;
    font-size: 12px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    display: inline-block;
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
    padding: 0 8px 0 8px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  div.vis-network div.vis-manipulation div.vis-button:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  }
  div.vis-network div.vis-manipulation div.vis-button:active {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  }
  /* div.vis-network div.vis-manipulation div.vis-button.vis-back {
      background-image: url(img/network/backIcon.png);
  } */
  div.vis-network div.vis-manipulation div.vis-button.vis-none:hover {
    box-shadow: 1px 1px 8px transparent;
    cursor: default;
  }
  div.vis-network div.vis-manipulation div.vis-button.vis-none:active {
    box-shadow: 1px 1px 8px transparent;
  }
  div.vis-network div.vis-manipulation div.vis-button.vis-none {
    padding: 0;
  }
  div.vis-network div.vis-manipulation div.notification {
    margin: 2px;
    font-weight: 700;
  }
  
  div.vis-network div.vis-edit-mode div.vis-button.vis-edit.vis-edit-mode {
    background-color: #fcfcfc;
    border: 1px solid #ccc;
  }
  
  div.vis-network div.vis-edit-mode div.vis-label,
  div.vis-network div.vis-manipulation div.vis-label {
    margin: 0 0 0 23px;
    line-height: 25px;
  }
  div.vis-network div.vis-manipulation div.vis-separator-line {
    float: left;
    display: inline-block;
    width: 1px;
    height: 21px;
    background-color: #bdbdbd;
    margin: 0 7px 0 15px;
  }
  div.vis-network div.vis-navigation div.vis-button {
    width: 34px;
    height: 34px;
    -moz-border-radius: 17px;
    border-radius: 17px;
    position: absolute;
    display: inline-block;
    background-position: 2px 2px;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  div.vis-network div.vis-navigation div.vis-button:hover {
    box-shadow: 0 0 3px 3px rgba(56, 207, 21, 0.3);
  }
  div.vis-network div.vis-navigation div.vis-button:active {
    box-shadow: 0 0 1px 3px rgba(56, 207, 21, 0.95);
  }
  